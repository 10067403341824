@import "/src/styles/base/colors";
@import "/src/styles/base/text";

.btn {
  @extend .bold;
  color: white;
  font-size: 15px;
  padding: 7px 2rem;
  border: none;
  border-radius: 100px;
  line-height: 1;
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus {
    box-shadow: none;
  }

  &.w-auto {
    width: auto !important;
  }
  &.w-lg-auto {
    @media (min-width: 960px) {
      width: auto !important;
    }
  }

  &-primary {
    @extend .bg-color-light-blue-1;

    $bg-color: map-get($palette, "light-blue-1");

    &:hover,
    &:focus,
    &:active {
      background-color: darken($bg-color, 5%) !important;
    }
  }

  &-shadow {
    box-shadow: 0px 2px 17px rgba(77, 181, 255, 0.4);
  }

  &--small {
    height: 36px !important;
    width: 140px !important;
    padding: 0 !important;
  }

  &--extended {
    padding: 6px 54px;
  }

  &-mobile {
    border-radius: 0;
    text-align: center;
    min-height: 50px;
  }

  &-mobile-fixed {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    border-radius: 0;
    text-align: center;
    min-height: 50px;
    z-index: 500;

    @media (min-width: 960px) {
      position: relative;
      width: 180px;
      border-radius: 100px;
      min-height: 40px;
    }
  }

  &-square {
    border-radius: 0;
    font-weight: normal;
    font-size: 12px;
  }

  &-secondary {
    $bg-color: #e8f8ff;
    $color: map-get($palette, "light-blue-1");
    background-color: $bg-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
      color: darken($color, 5%) !important;
      background-color: darken($bg-color, 5%) !important;
    }
  }

  &-yellow {
    $bg-color: map-get($palette, "yellow-1");
    $color: map-get($palette, "black-1");
    background-color: $bg-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
      color: darken($color, 5%) !important;
      background-color: darken($bg-color, 5%) !important;
    }
  }

  &-melon {
    $bg-color: map-get($palette, "melon");
    $color: map-get($palette, "white");
    background-color: $bg-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
      color: darken($color, 5%) !important;
      background-color: darken($bg-color, 5%) !important;
    }
  }

  &-light {
    border: 2px solid map-get($palette, "light-blue-1");
    background-color: white !important;
    color: map-get($palette, "light-blue-1");
    
    &:hover,
    &:focus,
    &:active {
      color: darken(map-get($palette, "light-blue-1"), 5%) !important;
    }
    
    &:disabled {
      border: 2px solid map-get($palette, "light-blue-1") !important;
      color: map-get($palette, "light-blue-1") !important;
      background: #FAFAFA !important;
    }
  }

  &-link {
    @extend .bold;
    padding: 0;
    color: map-get($palette, "light-blue-1");

    &:hover {
      color: map-get($palette, "light-blue-1");
    }
  }

  &-link--back {
    @extend .bold;
    color: map-get($palette, "light-blue-1");

    &:hover {
      color: map-get($palette, "light-blue-1");
    }
  }

  &-fixed-right {
    right: 0;
    left: unset;
  }

  &-shadow {
    box-shadow: 0px 2px 17px rgba(77, 181, 255, 0.4);
  }

  &--small {
    height: 36px !important;
    width: 140px !important;
    padding: 0 !important;
  }

  &--extended {
    padding: 6px 54px;
  }

  &-skyBlue {
    $bg-color: map-get($palette, "skyBlue");
    $color: map-get($palette, "white");
    background-color: $bg-color;
    color: $color;

    &:hover,
    &:focus,
    &:active {
      color: darken($color, 5%) !important;
      background-color: darken($bg-color, 5%) !important;
    }
    filter: drop-shadow(4px 4px 10px #0071ce36);
  }

  &.white {
    color: white;
    border-color: white;
  }

  &.btn-shadow {
    box-shadow: $shadow-button;
  }

  &.mobile-w50 {
    @media (max-width: 960px) {
      width: 50vw !important;
    }
  }

  &.hide-desktop {
    @media (min-width: 960px) {
      display: none;
    }
  }

  &:hover {
    text-decoration: none;
    border-color: map-get($palette, "light-blue-1");
  }
}

button:disabled {
  border: none;
  background: #e0e0e0 !important;
  color: white;
  opacity: 1;
  pointer-events: none;

  &.disabled--yellow {
    color: map-get($palette, "grey-8") !important;
    background: rgba(255, 255, 255, 0.5) !important;
  }
}

button.mat-focus-indicator:disabled {
  background: transparent !important;
}
