$palette: (
  "light-blue-1": #4db5ff,
  "light-blue-2": rgba(77, 181, 255, 0.5),
  "light-blue-3": rgba(77, 181, 255, 0.1),
  "yellow-1": #fed47f,
  "yellow-2": rgba(255, 223, 128, 0.5),
  "yellow-3": rgba(255, 223, 128, 0.3),
  "melon": #ee7185,

  "blue": #0b3bdf,
  "skyBlue": #0071CE,
  "green": #1ed030,

  "white": #ffffff,

  "black-1": #000000,
  "black-2": #1b1b1b,
  "black-3": #161616,
  "black-4": #3c3c3c,
  "black-5": #1d1d1b,
  "black-6": #323232,
  "black-7": #1a1a1a,
  "black-8": #141414,

  "grey-1": #5b5959,
  "grey-2": #acacaa,
  "grey-3": #e0e0e0,
  "grey-4": #676767,
  "grey-5": #383838,
  "grey-6": #2b2b2b,
  "grey-7": #f7f7f7,
  "grey-8": #787878,
  "grey-9": #5f5f5f,
  "grey-10": #575756,
  "grey-11": #cdcdcd,
  "grey-12": #b3b3b3,

  "red-1": #f80000,
  "red-2": #d93025,
  "red-3": rgba(255, 133, 140, 0.5),
  "red-4": rgba(217, 48, 37, 0.08),
  "red-5": #ee7185,

  "purple-1": #a312ba,
  "purple-2": #a212ba,
  "blue-1": #0031e2,
  "blue-2": #0251ec,
);

@each $color-name, $color-value in $palette {
  .color-#{$color-name} {
    color: $color-value !important;
  }
  .bg-color-#{$color-name} {
    background-color: $color-value !important;
  }
}
